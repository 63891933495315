<template>
  <div>
    <!-- 検索フォーム -->
    <v-card color="ffffff" outlined width="100%" style="margin: 20px 0px">
      <v-card-title class="orange--text text--dark-1">検索</v-card-title>
      <v-card-text style="font-size: 25px">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="modelId"
              label="問題ID"
              hint="半角英数字8文字"
              persistent-hint
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-select
              multiple
              v-model="selSections"
              :items="itemsSections"
              item-text="text"
              label="セクション（複数選択可）"
              return-object
            >
              <template v-slot:selection="{ index }">
                <span v-if="index == 0"
                  >☑ {{ selSections.length }} selected</span
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="modelKeyword"
              label="キーワード（スペース区切りで複数入力可）"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- 検索結果 -->
    <v-card color="ffffff" outlined width="100%" style="margin-top: 0px">
      <v-card-title
        style="margin-bottom: -20px"
        class="orange--text text--dark-1"
        >検索結果</v-card-title
      >
      <v-card-text>
        <div class="scroll-table">
          <v-data-table
            :headers="headers"
            :items="searchResult"
            :items-per-page="400"
            fixed-header
            height="500px"
            mobile-breakpoint="0"
            hide-default-footer
          >
            <!-- 問題ID -->
            <template v-slot:[`item.id`]="searchResult">
              <v-btn
                text
                @click="openQuestion(searchResult.item.id)"
                color="blue"
                style="text-transform: none; padding: 0px 5px"
                >{{ searchResult.item.id }}</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
    <div v-if="!isLogedIn">
      <v-dialog
        overlay-color="white"
        persistent
        width="500"
        v-model="isShowSearch"
      >
        <v-card class="modal" color="#dddddd">
          <a href="/">アカウント作成（無料）</a>
          すると問題検索機能をご利用いただけます
          <div>
            <a :href="kamokuName">戻る</a>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style>
.scroll-table {
  overflow: hidden;
  white-space: nowrap;
}
.v-data-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
.modal {
  padding: 30px;
}
.modal a:hover {
  color: #0099ff;
}
.modal div {
  text-align: right;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'result',
  components: {},
  data() {
    return {
      kamokuName: '', //親コンポーネントから受け取る
      questions: [], //問題データ、stateからコピーしておく
      sectionList: [], //セクションリストのデータ、stateからコピーしておく。
      modelId: '', //ユーザーが記入するID
      itemsSections: [], //セクションリストのアイテム、{ sectionId:sectionId, text:sectionName }
      selSections: [], //選択されたセクション
      modelKeyword: '', //ユーザが記入するキーワード

      searchResult: [], //検索結果
      headers: [
        { text: '問題ID', value: 'id', width: '100px' },
        { text: '単元', value: 'tangenName', width: '200px' },
        { text: 'セクション', value: 'sectionName', width: '200px' },
        { text: '問題文', value: 'question', width: '800px' },
      ],
    };
  },
  computed: {
    ...mapState(['webApp']),
    isShowSearch() {
      return this.webApp.drawer.list.search;
    },
    isLogedIn() {
      return this.gf_isLogin();
    },
  },
  watch: {
    modelId() {
      this.searchQuestions();
    },
    selSections() {
      this.searchQuestions();
    },
    modelKeyword() {
      this.searchQuestions();
    },
  },
  methods: {
    //======================================================================================
    setContent(kamokuName) {
      this.kamokuName = kamokuName;
      const questions = this.$store.state[kamokuName].questions;
      const q = questions.questions;
      const arrayObj_q = this.utils_create_arrayObjFromObj(q);
      const arrayObj = [];
      arrayObj_q.forEach((ele) => {
        if (ele.status == 'private') {
          return;
        }
        arrayObj.push(ele);
      });
      this.questions = arrayObj; //privateを除去済みのデータを格納
      let s = questions.sectionList;

      if (!s) {
        s = questions.sectionsList; //旧データでエラーなら新データを読み込む
      }

      this.sectionList = this.utils_create_arrayObjFromObj(s);
      this.createItemsSections(kamokuName);
    },
    createItemsSections(kamokuName) {
      const sectionObj = this.$store.state[kamokuName].sectionObj;
      const items = [];
      Object.keys(sectionObj).forEach((tangenId) => {
        const sections = sectionObj[tangenId]['sections'];
        Object.keys(sections).forEach((sectionId) => {
          const amount = this.questions.filter(
            (data) => data['sectionId'] == sectionId,
          ).length;
          const sectionName =
            sections[sectionId]['name'] + '（' + amount + '）';
          const data = {
            sectionId: sectionId,
            text: sectionName,
          };
          items.push(data);
        });
      });
      this.itemsSections = items;
    },
    searchQuestions() {
      this.searchResult = []; //検索結果の初期化
      if (this.modelId != '') {
        this.searchById(); //ヒットしたらそのまま格納します
      }
      if (this.selSections.length != 0) {
        this.searchBySectionId(); //ヒットしたらそのまま格納します
      }
      if (this.modelKeyword != '') {
        this.searchByKeyword(); //ヒットしたらそのまま格納します
      }
      this.searchResult = this.deleteDeplicate(this.searchResult); //id基準で重複を削除する

      // TODO:新データ以降完了後 this.insertInfoForNewData()のみにする
      if (this.gf_isNewType(this.kamokuName)) {
        this.insertInfoForNewData();
      } else {
        this.insertInfo(); //表示用に単元名やセクション名を挿入する
      }
    },
    searchById() {
      this.questions.forEach((ele) => {
        const isIncluded = String(ele.id).includes(this.modelId);
        if (isIncluded) {
          this.searchResult.push(ele);
        }
      });
    },
    searchBySectionId() {
      const arrSectionId = [];
      this.selSections.forEach((ele) => {
        arrSectionId.push(ele['sectionId']);
      });
      arrSectionId.forEach((sectionId) => {
        const arr = this.questions.filter(
          (data) => data['sectionId'] == sectionId,
        );
        arr.forEach((ele) => {
          this.searchResult.push(ele); //ヒットしたデータを格納
        });
      });
    },
    searchByKeyword() {
      const arrKeywords = this.modelKeyword.split(/\s+/); //1以上の連続する空白で区切る。半角、全角両方判定している。
      const targetKeys = [
        //検索対象となる問題データのkeyを定義する。twoWay非対応
        'question',
        'answer',
        'dummy',
        'comment',
      ];
      arrKeywords.forEach((keyword) => {
        if (keyword == '') {
          return;
        } //keywordが空白の場合は次
        targetKeys.forEach((key) => {
          const reg = new RegExp(keyword, 'g');
          const arr = this.questions.filter((data) => data[key].match(reg));
          arr.forEach((ele) => {
            this.searchResult.push(ele); //ヒットしたデータを格納
          });
        });
      });
    },
    deleteDeplicate(arr) {
      //idを基準に重複を削除する
      var result = arr.filter(
        (element, index, self) =>
          self.findIndex((e) => e['id'] === element['id']) === index,
      );
      return result;
    },
    insertInfo() {
      this.searchResult.forEach((ele) => {
        const sectionId = ele['sectionId'];
        const ind = this.sectionList.findIndex(
          (data) => data['sectionId'] == sectionId,
        );
        const sectionName = this.sectionList[ind]['name'];
        const tangenId = this.sectionList[ind]['tangenId'];

        const ind2 = this.sectionList.findIndex(
          (data) => data['sectionId'] == tangenId,
        );
        const tangenName = this.sectionList[ind2]['name'];

        ele['tangenName'] = tangenName;
        ele['sectionName'] = sectionName;
      });
    },
    insertInfoForNewData() {
      const kamokuName = this.kamokuName;
      const obj = this.$store.state[kamokuName].questions.tangensList;
      const tangensList = this.utils_create_arrayObjFromObj(obj);

      this.searchResult.forEach((ele) => {
        const sectionId = ele['sectionId'];
        const ind = this.sectionList.findIndex(
          (data) => data['id'] == sectionId,
        );
        const sectionName = this.sectionList[ind]['name'];
        const tangenId = this.sectionList[ind]['tangenId'];

        const ind2 = tangensList.findIndex((data) => data['id'] == tangenId);
        const tangenName = tangensList[ind2]['name'];

        ele['tangenName'] = tangenName;
        ele['sectionName'] = sectionName;
      });
    },
    //その他機能
    //=========================================================================================================================================
    openQuestion(id) {
      const obj_q = this.$store.state[this.kamokuName].questions.questions;
      const arrayObj_q = this.utils_create_arrayObjFromObj(obj_q);
      const q = arrayObj_q.filter((data) => data.id == id)[0];

      //問題を新しいタブで開く
      const data = {
        isSearch: true,
        selKamokuName: this.kamokuName,
        userData: id,
        question: q,
      };
      sessionStorage.setItem('data', JSON.stringify(data));
      let resolvedRoute = this.$router.resolve({ path: '/showQuestion' }); //parmsできないのでSSで…
      window.open(resolvedRoute.href, '_blank');
    },
  },
};
</script>
