<template>
  <div>
    <center>
      <div class="scroll-table">
        <v-data-table
          v-model="selectedExam"
          :headers="headers"
          :items="items"
          :items-per-page="400"
          fixed-header
          height="500px"
          mobile-breakpoint="0"
          hide-default-footer
          disable-sort
          dense
        >
          <!-- 学習日 -->
          <template v-slot:[`item.date`]="items">
            <v-btn
              text
              @click="openResult(items.item.id)"
              color="blue"
              style="text-transform: none; padding: 0px 5px"
            >
              {{ items.item.date }}
            </v-btn>
          </template>
          <!-- 正答率 -->
          <template v-slot:[`item.correctPer`]="items">
            {{ items.item.correctPer }}
          </template>
          <!-- 選択したセクション数 -->
          <template v-slot:[`item.sections`]="items">
            <v-menu
              :v-model="false"
              :close-on-content-click="false"
              offset-x
              open-on-hover
            >
              <template v-slot:activator="{ on }">
                <a style="text-decoration: none">
                  <div v-on="on" style="width: 50px">
                    <b>{{ items.item.sections.amountSections }}</b>
                  </div>
                </a>
              </template>
              <v-card width="350px">
                <v-card-text>
                  <span style="white-space: pre-wrap; word-wrap: break-word">
                    {{ items.item.sections.sectionsStrList }}
                  </span>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
          <!-- 進度 -->
          <template v-slot:[`item.progress`]="items">
            <v-progress-linear
              :value="items.item.progress.progress"
              color="orange lighten-1"
              :buffer-value="items.item.progress.buffer"
              stream
              height="20"
              width="100%"
            >
              <div style="width: 100%">
                {{ items.item.progress.str }}
              </div>
            </v-progress-linear>
          </template>
        </v-data-table>
      </div>
    </center>
  </div>
</template>

<style>
.scroll-table {
  overflow: hidden;
  white-space: nowrap;
}
.v-data-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
</style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'studyLogs',
  components: {},
  data() {
    return {
      //データテーブル
      items: [],
      selectedExam: [],
      headers: [
        { text: '学習日', value: 'date', width: '180px' },
        { text: '取組時間', value: 'time', width: '100px' },
        { text: 'モード', value: 'mode', width: '50px' },
        { text: '正解数', value: 'amount', width: '50px' },
        { text: '正答率', value: 'correctPer', width: '50px' },
        { text: 'ｾｸｼｮﾝ数', value: 'sections', width: '50px' },
        {
          text: '進度（ 連続正解 / 1度正解 / 不正解・未回答 ）',
          value: 'progress',
          width: '300px',
        },
      ],

      activityLogs: {},
    };
  },
  computed: {
    ...mapState(['user']),
  },

  methods: {
    setContent(kamokuName) {
      const kamoku_type =
        this.$store.state.conf.itemsKamoku[kamokuName].kamoku_type;
      //regular問題用にヘッダーを変形
      if (kamoku_type == 'regular') {
        const newHedaers = this.headers.filter((data) => data.value != 'mode');
        this.headers = newHedaers;
      }

      const obj_kmaoku = this.user.fs[kamokuName];
      const activityLogs = obj_kmaoku.activityLogs.data;
      this.activityLogs = activityLogs; //openResult()で使用する

      const arr = Object.keys(activityLogs);
      arr.sort((a, b) => Number(b) - Number(a)); //日付ミリ秒を昇順ソートします
      const arrayObj = [];
      arr.forEach((ms) => {
        const saveData = activityLogs[ms];
        const res = this.gf_getCorrectAmountAndPer(saveData);

        const progressObj = this.getProgressObjFromStateStatistics(
          ms,
          obj_kmaoku.stateStatistics.data,
          kamokuName,
        );

        const data = {
          id: ms, //result展開用
          date: this.utils_formatDate(new Date(Number(ms)), 'Y/M/D h:m'),
          time: this.gf_getActivityTimeFS(saveData),
          mode: saveData.option.selMode.text, //この列はコマンド科目の時のみ表示される、selMode自体は通常演習でも保持している
          amount: res.amount,
          correctPer: res.correctPer,
          sections: this.createSectionsStrList(saveData, kamokuName), //{sectionsStrList:sectionsStrList, amountSections:amountSections}
          progress: progressObj,
        };

        if (data.sections) {
          arrayObj.push(data);
        }
      });

      this.items = arrayObj;
    },
    createSectionsStrList(saveData, kamokuName) {
      const obj1 = saveData.selSectionId;
      const selSectionId = this.utils_create_arrayObjFromObj(obj1);

      // if (!isNaN(Number(selSectionId[0].sectionId))) { //sectionIdが数値のもの（旧データ）を除く
      //   return;
      // }

      const amountSections = selSectionId.length;
      const arrTangenId = [];
      selSectionId.forEach((ele) => {
        arrTangenId.push(ele.tangenId);
      });
      var unique = [...new Set(arrTangenId)];

      const optionInfo = this.getOptionInfo(saveData); //オプション選択情報の作成
      let sectionsStrList = optionInfo; //選択したセクションの一覧を記載する
      sectionsStrList += '\n\n';

      unique.forEach((tangenId) => {
        const arrSections = selSectionId.filter(
          (data) =>
            Number(data.sectionId) > Number(tangenId) &&
            Number(data.sectionId) < Number(tangenId) + 100,
        );
        let strSections = '';
        arrSections.forEach((ele) => {
          const sectionId = ele.sectionId;
          strSections +=
            '\n　・' + this.getNameFromSectionList(kamokuName, sectionId);
        });
        const tangenName = this.getNameFromSectionList(kamokuName, tangenId);

        var str = '【' + tangenName + '】';
        str += strSections;
        str += '\n';
        str += '\n';

        sectionsStrList += str;
      });

      return {
        sectionsStrList: sectionsStrList,
        amountSections: amountSections,
      };
    },
    getOptionInfo(saveData) {
      const isHint = saveData.option.selHint.value;
      let strHint = '選択肢数ヒント：なし';
      if (isHint) {
        strHint = '選択肢数ヒント：あり';
      }
      if (this.isCommand) {
        //コマンド科目ならばヒントの記載はなし
        strHint = '';
      }

      let selStateObj = saveData.option.selQuestionState;
      if (selStateObj === undefined) {
        selStateObj = saveData.option.selQuestionsState; //旧データはselQuestionsStateという名称です...すみません...
      }

      let strState = '';
      Object.keys(selStateObj).forEach((key) => {
        const text = selStateObj[key].text;
        strState += text + ' ';
      });

      let res = '';
      res += strHint;
      res += '\n出題対象：' + strState;
      return res;
    },
    getNameFromSectionList(kamokuName, sectionId) {
      const obj_sl =
        this.$store.state[kamokuName].questions[
          // TODO:新データへの移行が終わり次第sectionsListだけにする。
          this.gf_isNewType(kamokuName) ? 'sectionsList' : 'sectionList'
        ];
      const sectionList = this.utils_create_arrayObjFromObj(obj_sl);
      const sectionRecord = sectionList.filter(
        (data) =>
          // TODO:新データへの移行が終わり次第tangenIdだけにする。
          data[this.gf_isNewType(kamokuName) ? 'tangenId' : 'sectionId'] ==
          sectionId,
      )[0];
      return sectionRecord.name;
    },
    getProgressObjFromStateStatistics(key, stateStatistics, kamokuName) {
      const q = this.$store.state[kamokuName].questions.questions;
      const arrayObj_q = this.utils_create_arrayObjFromObj(q);
      const publicQ = arrayObj_q.filter((data) => data.status != 'private');
      const sum = Object.keys(publicQ).length;
      const obj = stateStatistics[key];
      if (!obj) {
        return {};
      }

      const numCC = Number(obj.correctConstant);
      const numCO = Number(obj.correctOnce);
      const numOth = sum - numCC - numCO;
      const progress = Math.round((numCC / sum) * 100);
      const buffer = Math.round(((numCC + numCO) / sum) * 100);

      const str = `◎${numCC} / 〇${numCO} / ✕未${numOth}`;
      const progressObj = {
        progress: progress,
        buffer: buffer,
        str: str,
      };

      return progressObj;
    },
    openResult(id) {
      const saveData = this.activityLogs[id];
      const data = {
        saveData: saveData,
      };
      sessionStorage.setItem('data', JSON.stringify(data));
      let resolvedRoute = this.$router.resolve({ path: '/result' }); //parmsできないのでSSで…
      window.open(resolvedRoute.href, '_blank');
    },
  },
};
</script>
