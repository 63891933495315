<template>
  <div>
    <p>
      団体で学習管理をされているユーザーの場合、ここに到達課題の一覧が表示されます。
    </p>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="4"
        v-for="ass in assObj"
        :key="ass.id"
        style="margin-bottom: 15px"
      >
        <v-card
          :color="ass.cardColor"
          hover
          ripple
          width="100%"
          @click="openInfo(ass.id)"
        >
          <v-card-title>
            {{ ass.option.assignmentTitle }}
            <span v-show="ass.vifAchieve" style="color: red"> 🈴</span>
          </v-card-title>
          <v-card-text>
            <span style="margin-right: 15px">期限：{{ ass.showDead }}</span
            >到達人数：{{ ass.amountAchieve }}人
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 課題ダイアログ、mounted段階ではassEleがないのでv-ifで表示させないようにする -->
    <v-dialog v-if="dialog" v-model="dialog" max-width="500px">
      <v-card>
        <div style="margin: 5px 5px; float: right">
          <a><v-icon @click="dialog = false">mdi-close</v-icon></a>
        </div>
        <v-card-title>{{ assEle.option.assignmentTitle }}</v-card-title>
        <v-card-text>
          <font size="4">
            <p><b>到達期限</b>：{{ assEle.showDead }}</p>
            <p><b>合格基準</b>：{{ assEle.option.selTargetPer }}%</p>
            <p>
              <b>出題範囲</b>
              <br />
              <span style="white-space: pre-wrap; word-wrap: break-word">
                {{ assEle.selSectionName }}
              </span>
            </p>
          </font>
          <center>
            <div style="max-width: 300px">
              <v-btn
                width="90%"
                rounded
                dark
                color="orange darken-1"
                @click="kamokuStart()"
              >
                <b>課題テストをはじめる</b>
              </v-btn>
            </div>
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'assignment',
  data() {
    return {
      kamokuName: '',
      assObj: [],
      dialog: false,
      assEle: [], //ダイアログ表示用のele
    };
  },
  computed: {
    //======================================================================================
    ...mapState(['user']),
  },
  methods: {
    //======================================================================================
    async setContent(kamokuName) {
      this.kamokuName = kamokuName;
      const companyId = this.user.claims.companyId;
      if (!companyId) {
        return;
      }

      await this.gf_getAssignment(); //普段はkeppLoginでリロード対策としてのみ実行。resultから戻った時用に個別で実行。
      await this.createAssObj();
    },
    //課題カードの作成
    createAssObj() {
      const obj = this.user.assignment.data;
      const arr = [];
      Object.keys(obj).forEach((ms) => {
        const ele = obj[ms];
        if (ele.selKamokuName == this.kamokuName) {
          let showDead = '期限なし';
          if (ele.deadline != 0 && ele.deadline != 9999999999999) {
            showDead = this.utils_formatDate(
              new Date(ele.deadline),
              'Y/M/D（day）',
            );
          } else {
            ele.deadline = 9999999999999; //ソートで最後に来るように
          }

          ele.showDead = showDead;
          ele.amountAchieve = Object.keys(ele.achievement).length;
          this.setCardColor(ele);
          arr.push(ele);
        }
      });
      //priceの昇順
      arr.sort((a, b) => {
        if (a.deadline > b.deadline) return 1;
        if (a.deadline < b.deadline) return -1;
        return 0;
      });
      this.assObj = [];
      this.assObj = arr;
    },
    setCardColor(ele) {
      const uid = this.user.auth.uid;
      const arr = Object.keys(ele.achievement);
      const ind = arr.indexOf(uid);
      ele.cardColor = 'green';
      //uidがあれば到達している証拠
      if (ind == -1) {
        ele.cardColor = 'blue lighten-5';
        ele.vifAchieve = false;
      } else {
        ele.cardColor = 'red lighten-5';
        ele.vifAchieve = true;
      }
      return;
    },

    //課題の表示
    //======================================================================================
    openInfo(id) {
      this.assEle = []; //初期化
      const ele = this.assObj.filter((data) => data.id == id)[0];
      this.setSelSectionName(ele);
      this.assEle = ele;
      this.dialog = true;
    },
    setSelSectionName(ele) {
      const obj1 = ele.selSectionId;
      const selSectionId = this.utils_create_arrayObjFromObj(obj1);

      const arrTangenId = [];
      selSectionId.forEach((ele) => {
        arrTangenId.push(ele.tangenId);
      });
      var unique = [...new Set(arrTangenId)];

      let sectionsStrList = '';
      unique.forEach((tangenId) => {
        const arrSections = selSectionId.filter(
          (data) =>
            Number(data.sectionId) > Number(tangenId) &&
            Number(data.sectionId) < Number(tangenId) + 100,
        );
        let strSections = '';
        arrSections.forEach((ele) => {
          const sectionId = ele.sectionId;
          strSections += '\n　・' + this.getNameFromSectionList(sectionId);
        });
        const tangenName = this.getNameFromSectionList(tangenId);
        var str = '【' + tangenName + '】';
        str += strSections;
        str += '\n';
        str += '\n';
        sectionsStrList += str;
      });
      ele.selSectionName = sectionsStrList;
    },
    getNameFromSectionList(sectionId) {
      const obj = this.$store.state[this.kamokuName].questions.sectionList;
      const sectionList = this.utils_create_arrayObjFromObj(obj);
      const sectionRecord = sectionList.filter(
        (data) => data['sectionId'] == sectionId,
      )[0];
      return sectionRecord.name;
    },
    async kamokuStart() {
      const ele = this.assEle;
      this.contQ_init({
        selSectionId: ele.selSectionId,
        kamokuName: this.kamokuName,
      });
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'option',
        data: ele.option,
      });
      //初期化後に課題フラグを格納
      this.$store.commit('update_store', {
        path: 'perm',
        key: 'isAssignment',
        data: true,
      });
      this.$store.commit('update_store', {
        path: 'perm',
        key: 'assignmentId',
        data: ele.id,
      });

      const kamoku_type = this.gf_get_kamokuType(this.kamokuName);
      if (kamoku_type == 'regular') {
        this.$router.push('/quiz');
        return;
      }
      if (kamoku_type == 'command') {
        this.$router.push('/quizCommand');
        return;
      }
    },
  },
};
</script>
